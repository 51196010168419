import styled from '@emotion/styled'
import { metricsPN, colorsPN } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../../../__styling'
import { perthNowNGNTextMixin } from '../../../../mixins'
import { StyledPNFigure } from '../PNArticleImage/PNArticleImage.styled'
import { StyledPNLiveEventTimestamp } from './PNLiveEventPostByline.styled'
import {
    StyledButtonContainer,
    StyledShowMoreButton,
    StyledTruncContainer,
} from '../../../../publication/LiveEvent/Components/utility/TruncatingContainer.styled'

const { spacing } = metricsPN

export const StyledPNLiveEventPost = styled('div')<{ noBotMargin?: boolean }>(
    ({ noBotMargin }) => ({
        padding: calcRem(spacing['L'], 0),
        marginTop: 0,
        background: colorsPN.background.base,
        borderBottom: `1px solid ${colorsPN.stroke.weak}`,
        '&:last-child': {
            marginBottom: calcRem(spacing['L']),
        },
        [`${StyledPNFigure}`]: {
            margin: 0,
        },
        [breakpoint('sm')]: {
            padding: calcRem(spacing['XL'], 0),
            '&:last-child': {
                marginBottom: calcRem(spacing['XL']),
            },
        },
        [breakpoint('md')]: {
            margin: 0,
            marginBottom: 0,
            '&:last-child': {
                marginBottom: calcRem(spacing['L']),
            },
        },
    }),
)

export const StyledPNLiveEventTitle = styled('h2')({
    margin: calcRem(metricsPN.spacing['S'], 0),
    color: colorsPN.text.primary,
    ...perthNowNGNTextMixin.title.S.semibold,

    [breakpoint('lg')]: {
        ...perthNowNGNTextMixin.title.L.semibold,
    },
})

export const StyledLiveEventSharingControls = styled('div')({
    display: 'flex',
    marginTop: calcRem(metricsPN.spacing['S']),
    gap: calcRem(spacing['2XS']),
})

export const StyledPNPinnedLiveEventPosts = styled('div')({
    borderBottom: 'none',

    [`${StyledTruncContainer}`]: {
        borderBottom: `1px solid ${colorsPN.stroke.weak}`,
        padding: 0,
        boxShadow: 'none',
        marginLeft: 0,
        marginRight: 0,

        [`${StyledPNLiveEventPost}`]: {
            borderBottom: 'none',
            marginBottom: 0,
            [breakpoint('sm')]: {
                marginLeft: calcRem(metricsPN.spacing['M']),
                marginRight: calcRem(metricsPN.spacing['M']),
            },

            [breakpoint('md')]: {
                marginLeft: 0,
                marginRight: 0,
            },
        },

        '&:last-child': {
            [`${StyledPNLiveEventPost}`]: {
                marginBottom: 0,
            },
        },

        '&:not(:first-child)': {
            marginTop: calcRem(metricsPN.spacing['L']),
        },

        [breakpoint('sm')]: {
            paddingTop: calcRem(metricsPN.spacing['L']),
        },

        [breakpoint('md')]: {
            paddingTop: 0,
        },
    },

    [`${StyledPNLiveEventPost}`]: {
        paddingTop: 0,
        marginBottom: 0,

        [breakpoint('md')]: {
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },

    [`${StyledPNLiveEventTimestamp}`]: {
        color: colorsPN.text.brand,
    },

    [`${StyledButtonContainer}`]: {
        padding: 0,
        marginBottom: calcRem(metricsPN.spacing['L']),
    },

    [`${StyledShowMoreButton}`]: {
        marginLeft: 0,
        border: 'none',
        color: colorsPN.text.brand,
        padding: 0,

        '&:hover': {
            background: 'none',
            textDecoration: 'underline',
        },

        '&:active, &:focus, &:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['4XS']),
        },
        '&:after': {
            border: 'none',
        },
        ...perthNowNGNTextMixin.body.L.medium,
    },
})

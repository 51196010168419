import { useMutation } from '@tanstack/react-query'
import {
    getAuth0DeleteAccountActionPath,
    encryptPass,
} from '@news-mono/web-common'

interface DeleteAccountProps {
    userId: string
    email: string
    currentPassword: string
    passEncryptionKey: string
}

export const useDeleteAccount = ({
    userId,
    email,
    currentPassword,
    passEncryptionKey,
}: DeleteAccountProps) => {
    return useMutation<{ result: boolean; message: string }>({
        mutationKey: ['delete-account-request'],
        mutationFn: async () => {
            const encryptedCurrentPassword = await encryptPass(
                currentPassword,
                passEncryptionKey,
            )

            const result = {
                result: false,
                message: 'Failed to delete account, please try again.',
            }

            if (!encryptedCurrentPassword) {
                return result
            }

            const response = await fetch(getAuth0DeleteAccountActionPath, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    current_password: encryptedCurrentPassword,
                    email,
                }),
            })

            if (response.status === 401) {
                return {
                    result: false,
                    message: 'Incorrect password, please try again.',
                }
            }

            if (response.status === 204) {
                return {
                    result: true,
                    message: 'Password successfully updated.',
                }
            }

            return result
        },
    })
}

import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { WebLink, WebLinkProps } from '@news-mono/web-common'
import { CardMedia } from '../../../../cards/CardMedia/CardMedia'
import { perthNowNGNTextMixin } from '../../../../mixins/TextMixin/TextMixin.theme'
import { filterComponent } from '../../../../__helpers/filter-component'
import { breakWords, calcRem, breakpoint } from '../../../../__styling'
import { PNThemeCardTheme, cardThemes } from './themes'

export interface StyledThemeCardProps {
    cardTheme: PNThemeCardTheme
}

export const StyledThemeCardWrapper = styled('div')(() => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',

    [breakpoint('sm')]: {
        gridTemplateColumns: `repeat(5, 1fr)`,
        gridGap: calcRem(metricsPN.spacing['L']),
    },

    [breakpoint('lg')]: {
        gridTemplateColumns: '1fr',
    },
}))

export const StyledThemeCard = styled('aside')<StyledThemeCardProps>(
    ({ cardTheme }) => [
        {
            ...breakWords,
            position: 'relative',
            width: '100%',
            display: 'block',
            backgroundColor: cardThemes[cardTheme].background,
            borderRadius: `${cardThemes[cardTheme].border.radius}px`,
            overflow: 'hidden',

            [breakpoint('sm')]: {
                gridColumnStart: 2,
                gridColumnEnd: 5,
            },

            [breakpoint('lg')]: {
                gridColumnStart: 'unset',
                gridColumnEnd: 'unset',
            },
        },
        cardThemes[cardTheme].border.color && {
            border: `1px solid ${cardThemes[cardTheme].border.color}`,
        },
    ],
)

const FilteredWebLink = filterComponent<WebLinkProps, string>(WebLink, [
    'theme',
    'hasPadding',
    'isLoading',
    'cardTheme',
])

export const StyledLink = styled(FilteredWebLink)<StyledThemeCardProps>(
    ({ cardTheme }) => [
        {
            textDecoration: 'none',
            display: 'flex',
            width: '100%',
            textDecorationSkipInk: 'auto',
            flexFlow: 'column nowrap',
            backgroundColor: cardThemes[cardTheme].button.fill,
            borderRadius: `${cardThemes[cardTheme].button.border.radius}px`,
            padding: calcRem(metricsPN.spacing['XS'], metricsPN.spacing['L']),
            color: cardThemes[cardTheme].button.text.color,
            textAlign: 'center',
            ...perthNowNGNTextMixin.label.L.semibold,

            '&:hover': {
                backgroundColor: cardThemes[cardTheme].button.hover,
            },

            '&:active': {
                backgroundColor: cardThemes[cardTheme].button.pressed,
            },

            '&:focus-visible': {
                outline: 'revert',
                outlineOffset: calcRem(metricsPN.spacing['4XS']),
            },
        },
        cardThemes[cardTheme].button.border.color && {
            border: `1px solid ${cardThemes[cardTheme].button.border.color}`,
        },
    ],
)

export const StyledCardTextContainer = styled('div')<StyledThemeCardProps>(
    ({ cardTheme }) => ({
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'left',
        width: '100%',
        color: cardThemes[cardTheme].text.color,
        padding: calcRem(
            metricsPN.spacing['S'],
            metricsPN.spacing['M'],
            metricsPN.spacing['M'],
            metricsPN.spacing['M'],
        ),
    }),
)

export const StyledHeadline = styled('h2')(() => {
    return {
        display: 'inline',
        ...perthNowNGNTextMixin.title.M.semibold,
        margin: 0,
        marginBottom: calcRem(metricsPN.spacing['XS']),
        [breakpoint('lg')]: {
            ...perthNowNGNTextMixin.title.L.semibold,
        },
    }
})

export const StyledCardMedia = styled(CardMedia)(() => ({
    flex: 1,
    width: '100%',
    height: 'fit-content',
    margin: `0 auto`,
}))

export const StyledTeaser = styled.p(() => ({
    ...perthNowNGNTextMixin.body.L.regular,
    margin: 0,
    marginBottom: calcRem(metricsPN.spacing['M']),
}))

export const StyledStreamerScreamerLogo = styled('img')(() => ({
    height: calcRem(30),
}))

export const StyledStreamerBHPLogo = styled('img')((props) => ({
    height: calcRem(20),
    position: 'relative',
    top: '2px',
}))

export const StyledLogoLink = styled(WebLink)(() => ({
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['4XS']),
        borderRadius: `${metricsPN.radius['S']}px`,
    },
}))

export const StyledBannerWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: calcRem(metricsPN.spacing['S'], metricsPN.spacing['M'], 0),
    flexWrap: 'nowrap',
    width: '100%',
}))

import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { StyledAdUnitWrapper } from '../../../../advertising/AdUnit/AdUnit.styled'
import { breakpoint } from '../../../../__styling'

export const StyledPNLiveEventTimeline = styled('div')(({ theme }) => [
    {
        minHeight: '100vh',
        background: colorsPN.background.base,

        [`${StyledAdUnitWrapper}`]: {
            marginTop: metricsPN.spacing.L,

            [breakpoint('sm')]: {
                marginTop: metricsPN.spacing.XL,
            },
        },
    },
])

import React, { FC, Fragment } from 'react'
import { PNLiveEventPost } from './PNLiveEventPost'
import { StyledPNLiveEventTimeline } from './PNLiveEventTimeline.styled'
import { AdUnitWrapper } from '../../../../advertising'
import { useIsComponentOnScreen } from '../../../../publication/LiveEvent/helpers'
import { LiveEventTimelineProps } from '../../../../publication/LiveEvent/Components'
import { PNLiveEventPinnedPosts } from './PNLiveEventPinnedPosts'

export const PNLiveEventTimeline: FC<LiveEventTimelineProps> = ({
    posts,
    adUnits,
    eventTitle,
    log,
    triggerLazyLoad,
    onEvent,
    divRef,
    pinnedPosts,
    adState,
    deepLinkedPostID,
    entryType,
}) => {
    const { componentRef } = useIsComponentOnScreen('0px', triggerLazyLoad)

    const hasPinnedPosts = pinnedPosts.length > 0

    return (
        <StyledPNLiveEventTimeline ref={divRef}>
            {hasPinnedPosts && (
                <PNLiveEventPinnedPosts
                    pinned={pinnedPosts}
                    eventTitle={eventTitle}
                    log={log}
                    adUnitPath={adState.targeting.adUnitPath}
                    onEvent={onEvent}
                />
            )}
            {posts.map((post, index) => (
                <Fragment key={post.id}>
                    <PNLiveEventPost
                        post={post}
                        log={log}
                        eventTitle={eventTitle}
                        adUnitPath={adState.targeting.adUnitPath}
                        lazyLoadRef={
                            index === posts.length - 2
                                ? componentRef
                                : undefined
                        }
                        onEvent={onEvent}
                        isActivePost={deepLinkedPostID === post.id}
                        entryType={entryType}
                    />
                    {index in adUnits && (
                        // Add adunit if expected here.
                        <AdUnitWrapper
                            noticePosition="below-center"
                            padding={[4, 0, 4, 0]}
                            adState={adState}
                            unitId={adUnits[index].id}
                            onEvent={onEvent}
                            adType={'inline'}
                        />
                    )}
                </Fragment>
            ))}
        </StyledPNLiveEventTimeline>
    )
}

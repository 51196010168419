import {
    adPaddingOptions,
    CngNewsletter,
    getInlineEventAds,
    inlineArticleAds,
    PerthNowSection,
    teadsAdSlotID,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Product,
    publicationAdKind,
    publicationAdPageType,
    RenderTarget,
    TogglesReduxState,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getCngPromoLayout, getPromoLayout } from '../../common/promoLayout'
import {
    sidebarCngDontMiss,
    sidebarCollectionShopNow,
    sidebarCollectionAroundTheSite,
    sidebarCollectionTaboola,
} from '../../common/sidebarCollection'
import { socialBannerSegment } from '../../socialBanner'
import { getAmpArticleComposition } from './article.amp'
import { getNoIndexMeta } from './allowList'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

export const createArticleRoute = ({
    article,
    config,
    section,
    getAdTargeting,
    renderTarget,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: PerthNowSection
    getAdTargeting: GetRouteAdTargeting
    renderTarget: RenderTarget
    toggles?: TogglesReduxState
}): PageType<PerthNowSection> => {
    const featureState = toggles && toFeatureState(toggles)
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        publicationAdKind[article.kind],
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const isCommunityNews = section === 'community-news'
    const isShopNow = section === 'shop-now'

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const cngFollowComponent = isCommunityNews
        ? [
              layout.nestedComposition({
                  type: 'box',
                  props: {
                      verticalSpacing: 'md',
                  },
                  contentAreas: {
                      main: [
                          layout.component({
                              type: 'pnln-social-banner',
                              props: { compact: true },
                          }),
                      ],
                  },
              }),
          ]
        : []

    const ripple = [
        layout.nestedComposition({
            type: 'janus',
            feature: 'ripple',
            props: {
                stretchColumns: true,
                layoutRatio: '1:1',
            },
            contentAreas: {
                left: [
                    layout.component({
                        type: 'ripple',
                        props: {
                            pageSection: getRippleSectionTargeting(
                                isCommunityNews,
                                article,
                            ),
                        },
                    }),
                ],

                right: [
                    layout.component({
                        type: 'ripple',
                        props: {
                            pageSection: getRippleSectionTargeting(
                                isCommunityNews,
                                article,
                            ),
                        },
                    }),
                ],
            },
        }),
    ]

    // One generalised feature state checker
    const isFeature = (feature: string) =>
        (featureState && isFeatureEnabled(featureState, feature, false)) ??
        false

    const showPNLocalDigitalEditionDriver = Boolean(
        isFeature('PerthNow-in-article-digital-edition-driver') &&
            (article.topics.primary.id.includes('local-news') ||
                article.topics.secondary.find((topic) =>
                    topic.id.includes('local-news'),
                ) ||
                isCommunityNews),
    )

    const enableExtraInlineArticleAds = true

    const enableTheGameDriver = isFeature('the-game-article-driver')
    const enableNightlyPromoBanner = isFeature('tn-promo-banner')
    const enableTeadsAds = isFeature('teads-article-ads')
    const enableTaboolaSkimlinkWidget = isFeature('taboola-skimlinks')

    const enableOlympicsMedalTallyWidget =
        article.topics.primary.id.includes('sport') &&
        isFeature('paris-olympics-widgets')

    const sidebarCollection = () => {
        if (isCommunityNews) {
            return sidebarCngDontMiss()
        } else if (isShopNow) {
            return enableTaboolaSkimlinkWidget
                ? sidebarCollectionTaboola()
                : sidebarCollectionShopNow({ pageSize: 9 })
        } else {
            return sidebarCollectionAroundTheSite({
                pageSize: 4,
                offset: 0,
            })
        }
    }

    const extraInlineArticleAds = [
        inlineArticleAds({
            insertAfter: 3,
            noticePosition: 'below-center',
            hiddenUntilLoaded: false,
            mastHead: 'thewest',
            slotID: 'article-mrec-one',
            size: enableExtraInlineArticleAds ? 'alwaysMrec' : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 12,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thewest',
            slotID: 'article-mrec-two',
            size: enableExtraInlineArticleAds ? 'alwaysMrec' : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 21,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thewest',
            slotID: 'article-mrec-three',
            size: enableExtraInlineArticleAds ? 'alwaysMrec' : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 30,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thewest',
            slotID: 'article-mrec-four',
            size: enableExtraInlineArticleAds ? 'alwaysMrec' : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
        }),

        ...(enableTeadsAds
            ? [
                  inlineArticleAds({
                      insertAfter: 5,
                      noticePosition: 'below-center',
                      hiddenUntilLoaded: false,
                      mastHead: 'thewest',
                      slotID: teadsAdSlotID,
                      size: 'teads',
                      pageType: publicationAdPageType[article.kind],
                  }),
              ]
            : []),
    ]

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            title: article.seoHead || undefined,
            description: article.homepageTeaser,
            meta: getNoIndexMeta(article.source, featureState),
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        section,
        additionalPageProperties: {
            primaryTopic: article.topics.primary,
            secondaryTopics: article.topics.secondary,
            publicationKind: article.kind,
        },
        compositions:
            renderTarget === 'amp'
                ? [getAmpArticleComposition(article, section, config)]
                : [
                      layout.composition({
                          type: 'zeus',
                          props: { hasBackgroundFill: true },
                          contentAreas: {
                              sidebar: [
                                  layout.component({
                                      type: 'ad-unit',
                                      props: {
                                          noticePosition: 'below-center',
                                          padding: [0, 0, 13, 0],
                                          slot: {
                                              id: 'sidebar-top',
                                              size: 'desktopMrecHalfPage',
                                          },
                                          adType: 'inline',
                                      },
                                  }),
                                  ...(isCommunityNews
                                      ? [
                                            getCngPromoLayout(
                                                article.topics.primary.id,
                                            ),
                                        ]
                                      : [
                                            getPromoLayout(
                                                article.topics.primary.parent
                                                    ? article.topics.primary
                                                          .parent.id
                                                    : article.topics.primary.id,
                                            ),
                                        ]),
                                  ...cngFollowComponent,
                                  sidebarCollection(),
                                  ...(!isCommunityNews
                                      ? [
                                            ...socialBannerSegment({
                                                size: 'small',
                                                verticalSpacing: 'sm',
                                            }),
                                        ]
                                      : []),
                                  layout.component({
                                      type: 'ad-unit',
                                      props: {
                                          noticePosition: 'below-center',
                                          padding: [13, 0],
                                          stickyOffset: 0,

                                          slot: {
                                              id: 'sidebar-middle',
                                              size: 'desktopMrec',
                                          },
                                          adType: 'inline',
                                      },
                                  }),
                              ],
                              main: [
                                  layout.component({
                                      type: 'ad-unit',
                                      feature: 'outstream',
                                      props: {
                                          noticePosition: 'none',
                                          hiddenUntilLoaded: false,
                                          padding: adPaddingOptions['thewest'],
                                          slot: {
                                              id: 'outstream2x2',
                                              size: 'outstream',
                                          },
                                          adType: 'inline',
                                      },
                                  }),
                                  layout.component({
                                      type: 'article',
                                      props: {
                                          meta: {
                                              slug: article.slug,
                                              kind: 'article',
                                              inlinePublicationContent: [
                                                  {
                                                      kind: 'the-west-live-in-article-banner',
                                                      insertAfter: 6,
                                                  },
                                                  {
                                                      kind: 'oovvuu-featured-card',
                                                      insertAfter: 7,
                                                      props: {
                                                          oovvuuUrl:
                                                              config.oovvuuUrl,
                                                          oovvuuPath:
                                                              config.oovvuuPath,
                                                      },
                                                  },
                                                  {
                                                      kind: 'inline-cng-newsletter-banner',
                                                      insertAfter: -2,

                                                      props: {
                                                          displayCondition:
                                                              section ===
                                                              'community-news',
                                                          visiblility: {
                                                              initial:
                                                                  'visible',
                                                              xxs: 'visible',
                                                              xs: 'hidden',
                                                              sm: 'hidden',
                                                              md: 'hidden',
                                                              lg: 'hidden',
                                                              xl: 'hidden',
                                                          },
                                                          ...CngNewsletter,
                                                      },
                                                  },
                                                  {
                                                      kind: 'inline-pn-digital-edition-promo',
                                                      insertAfter: 4,
                                                      props: {
                                                          displayCondition:
                                                              showPNLocalDigitalEditionDriver,
                                                      },
                                                  },
                                                  {
                                                      kind: 'inline-the-game-banner',
                                                      insertAfter: 10,
                                                      props: {
                                                          url: 'https://thewe.st/7t7gxe',
                                                          toggle: enableTheGameDriver,
                                                      },
                                                  },
                                                  {
                                                      kind: 'inline-nightly-promo-banner',
                                                      insertAfter: 5,
                                                      toggle: enableNightlyPromoBanner,
                                                  },
                                                  {
                                                      kind: 'inline-olympics-medal-widget',
                                                      insertAfter: 11,
                                                      toggle: enableOlympicsMedalTallyWidget,
                                                      content: [{}],
                                                  },
                                                  ...extraInlineArticleAds,
                                                  ...(article.kind === 'event'
                                                      ? getInlineEventAds(
                                                            Product.PerthNow,
                                                        )
                                                      : []),
                                              ],
                                          },
                                          dataDefinitionArgs: {
                                              publicationId: article.id,
                                          },
                                          section,
                                      },
                                  }),
                                  layout.component({
                                      feature: 'newsletter-subscription-banner',
                                      type: 'newsletter-signup',
                                      props: {
                                          text: 'The latest from PerthNow delivered to your inbox daily.',
                                          buttonLabel: 'Sign Up',
                                          verticalSpacing: 'md',
                                          mode: 'default',
                                      },
                                  }),
                                  layout.component({
                                      type: 'coral',
                                      feature: 'comments',
                                      props: {
                                          storyID: `${config.publicHostname}-${article.id}`,
                                          articleCommentsEnabled:
                                              article.allowCommenting,
                                          storyURL: `${article._self}`,
                                      },
                                  }),
                              ],
                          },
                      }),
                      layout.composition({
                          type: 'zeus',
                          feature: 'taboola',
                          props: { hasBackgroundFill: true },
                          contentAreas: {
                              main: [
                                  layout.component({
                                      type: 'taboola',
                                      props: {
                                          taboolaContainerId:
                                              'taboola-below-article-thumbnails',
                                          locationType: 'article',
                                      },
                                  }),
                                  ...ripple,
                              ],
                              sidebar: [],
                          },
                      }),
                      layout.composition({
                          type: 'zeus',
                          props: { hasBackgroundFill: true },
                          contentAreas: {
                              main: [
                                  ...(isCommunityNews
                                      ? [
                                            getCngPromoLayout(
                                                article.topics.primary.id,
                                                {
                                                    mobileOnly: true,
                                                },
                                            ),
                                        ]
                                      : [
                                            getPromoLayout(
                                                article.topics.primary.parent
                                                    ? article.topics.primary
                                                          .parent.id
                                                    : article.topics.primary.id,
                                                { mobileOnly: true },
                                            ),
                                        ]),
                              ],
                              sidebar: [],
                          },
                      }),
                  ],
    }
}

const getRippleSectionTargeting = (
    isCommunityNews: boolean,
    article: ArticleLikeV4DTO,
) => {
    if (isCommunityNews) {
        return article.topics.primary.id
    } else {
        return article.topics.primary.parent
            ? article.topics.primary.parent.id
            : article.topics.primary.id
    }
}

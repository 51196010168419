import React, { FC } from 'react'
import { StyledPNMilestonesContainer } from './PNLiveEventMilestones.styled'
import { TruncatingContainer } from '../../../../publication/LiveEvent/Components/utility/TruncatingContainer'
import { LiveEventMilestonesProps } from '../../../../publication/LiveEvent/Components/LiveEventMilestones'
import { PNLiveEventMilestoneContent } from './PNLiveEventMilestoneContent'

export const PNLiveEventMilestones: FC<LiveEventMilestonesProps> = ({
    milestones,
    onEvent,
}) => {
    return (
        <StyledPNMilestonesContainer>
            <TruncatingContainer
                customStyle={'milestones'}
                buttonText={['Show more', 'Show less']}
            >
                <PNLiveEventMilestoneContent
                    milestones={milestones}
                    onEvent={onEvent}
                />
            </TruncatingContainer>
        </StyledPNMilestonesContainer>
    )
}

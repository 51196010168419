import React from 'react'
import { TruncatingContainer } from '../../../../publication/LiveEvent/Components/utility/TruncatingContainer'
import { PNLiveEventPost } from './PNLiveEventPost'
import { PinnedPostsProps } from '../../../../publication/LiveEvent/Components/LiveEventPinnedPosts'
import { StyledPNPinnedLiveEventPosts } from './PNLiveEventPost.styled'

export const PNLiveEventPinnedPosts: React.FC<PinnedPostsProps> = ({
    eventTitle,
    pinned,
    log,
    adUnitPath,
    onEvent,
}) => {
    return (
        <StyledPNPinnedLiveEventPosts>
            {pinned.map((post) => (
                <TruncatingContainer
                    key={`pinned-${post.id}`}
                    customStyle={'pinned-post'}
                    buttonText={['Show more', 'Show less']}
                >
                    <PNLiveEventPost
                        post={post}
                        eventTitle={eventTitle}
                        log={log}
                        adUnitPath={adUnitPath}
                        onEvent={onEvent}
                        renderIDAnchorTag={false}
                        isPinnedPost={true}
                    />
                </TruncatingContainer>
            ))}
        </StyledPNPinnedLiveEventPosts>
    )
}

import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRegisterableComponent } from '@news-mono/web-common'
import { UserSetting, UserSettingEvent } from './UserSetting'

const queryClient = new QueryClient()

export const UserSettingRegistration = createRegisterableComponent(
    'user-setting',
    (_, { onEvent, config, log }) => {
        return (
            <QueryClientProvider client={queryClient}>
                <UserSetting
                    onEvent={onEvent as (event: UserSettingEvent) => void}
                    config={config}
                    log={log}
                />
            </QueryClientProvider>
        )
    },
)

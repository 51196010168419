import { createRegisterableComponent, NavItem } from '@news-mono/web-common'
import {
    RoutablePerthNowSection,
    TopicV4DTO,
} from '@west-australian-newspapers/publication-types'
import React from 'react'
import { PerthNowNavItem } from '../../../../routing'
import { PNTopicAccordion } from './PNTopicAccordion'

export interface PNTopicAccordionProps {
    topics: { primary: TopicV4DTO; secondary: TopicV4DTO[] }
    perthNowSectionNavItems: Record<RoutablePerthNowSection, PerthNowNavItem>
    subTopicNavItems: Record<RoutablePerthNowSection, NavItem[]>
}
export const PNTopicAccordionRegistration = createRegisterableComponent(
    'perthnow-topic-accordion',
    ({
        topics,
        perthNowSectionNavItems,
        subTopicNavItems,
    }: PNTopicAccordionProps) => {
        return (
            <PNTopicAccordion
                topics={topics}
                perthNowSectionNavItems={perthNowSectionNavItems}
                subTopicNavItems={subTopicNavItems}
            />
        )
    },
)

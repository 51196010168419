import { getAuth0EmailVerifyActionPath } from '@news-mono/web-common'
import { useMutation } from '@tanstack/react-query'

export const useSendVerifyEmail = (userId: string) => {
    return useMutation({
        mutationKey: ['send-verification-email'],
        mutationFn: async () => {
            const response = await fetch(getAuth0EmailVerifyActionPath, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            })
            if (!response.ok || response.status !== 201) {
                throw new Error(`Network error in send-verification-email`)
            }
            return true
        },
    })
}

import {
    DataLayerEventName,
    LiveBlogEvents,
    WebLink,
    SharingEvent,
} from '@news-mono/web-common'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'
import { default as React, useEffect, useState } from 'react'
import {
    StyledPNMilestone,
    StyledPNMilestonesTimeline,
    StyledPNMilestonesTimelineList,
    StyledPNMilestonesTimelineListItem,
    StyledPNMilestonesTitle,
    StyledPNMilestoneTimestamp,
    StyledPNMilestoneTitle,
} from './PNLiveEventMilestones.styled'

interface PNLiveEventMilestoneContentProps {
    milestones: EventPostV4DTO[]
    onEvent: (event: LiveBlogEvents | SharingEvent) => void
    truncatedContainerRef?: React.RefObject<HTMLDivElement>
}
export const PNLiveEventMilestoneContent = ({
    milestones,
    onEvent,
    truncatedContainerRef,
}: PNLiveEventMilestoneContentProps) => {
    const [dimMilestones, setDimMilestones] = useState(false)

    return (
        <>
            <StyledPNMilestonesTitle>Key Events</StyledPNMilestonesTitle>
            <StyledPNMilestonesTimeline>
                <StyledPNMilestonesTimelineList>
                    {milestones.map((m) => (
                        <WebLinkWrapper
                            milestone={m}
                            dimMilestones={dimMilestones}
                            onEvent={onEvent}
                            setDimMilestones={setDimMilestones}
                            parentRef={truncatedContainerRef}
                        />
                    ))}
                </StyledPNMilestonesTimelineList>
            </StyledPNMilestonesTimeline>
        </>
    )
}

const WebLinkWrapper = ({
    milestone,
    dimMilestones,
    onEvent,
    setDimMilestones,
    parentRef,
}: {
    milestone: EventPostV4DTO
    dimMilestones: boolean
    onEvent: (event: LiveBlogEvents | SharingEvent) => void
    setDimMilestones: (value: boolean) => void
    parentRef?: React.RefObject<HTMLDivElement>
}) => {
    const linkRef = React.createRef<HTMLDivElement>()
    const [focusable, setFocusable] = useState(0)

    useEffect(() => {
        const childTop = linkRef.current?.getBoundingClientRect().top
        const parentBottom = parentRef?.current?.getBoundingClientRect().bottom
        if (childTop && parentBottom) {
            // account for the top margin of the child as offset
            const offset = 100
            const isOverflowing = childTop > parentBottom - offset
            setFocusable(isOverflowing ? -1 : 0)
        }
    }, [linkRef, parentRef])

    return (
        <StyledPNMilestonesTimelineListItem>
            <WebLink
                key={milestone.id}
                to={milestone._self}
                openExternalInNewWindow={false}
                tabIndex={focusable}
            >
                <StyledPNMilestone
                    ref={linkRef}
                    dimmed={dimMilestones}
                    onMouseEnter={() => setDimMilestones(true)}
                    onMouseLeave={() => setDimMilestones(false)}
                    onClick={() => {
                        onEvent({
                            type: DataLayerEventName.milestoneClick,
                            originator: 'LiveEvent',
                            payload: {
                                publicationId: milestone.eventId,
                                postId: milestone.id,
                            },
                        })
                    }}
                >
                    <StyledPNMilestoneTimestamp
                        timestampStyle="relative"
                        time={milestone.publishedDate}
                    />
                    <StyledPNMilestoneTitle>
                        {milestone.title}
                    </StyledPNMilestoneTitle>
                </StyledPNMilestone>
            </WebLink>
        </StyledPNMilestonesTimelineListItem>
    )
}

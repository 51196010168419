import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../mixins/TextMixin/TextMixin.theme'
import { breakpointMax, calcRem } from '../../../__styling'

export const StyledTabList = styled.nav(() => ({
    display: 'flex',
    width: '100%',
    margin: calcRem(metricsPN.spacing['L'], 0),
    '& > div:not(:first-child)': {
        marginLeft: calcRem(metricsPN.spacing['2XS']),
    },

    '& > div:not(:last-child)': {
        marginRight: calcRem(metricsPN.spacing['2XS']),
    },
    [breakpointMax('xs')]: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        '& > div:not(:first-child)': {
            marginLeft: 0,
        },

        '& > div:not(:last-child)': {
            marginRight: 0,
        },
    },
}))

export const StyledTab = styled(WebLink)(() => ({
    cursor: 'pointer',
    padding: calcRem(metricsPN.spacing['2XS'], metricsPN.spacing['S']),
    ...perthNowNGNTextMixin.body.M.semibold,
    color: colorsPN.text.secondary,
    textDecoration: 'none',
    '&[aria-selected=true]': {
        borderBottom: `1px solid ${colorsPN.stroke.brand.strong}`,
        color: colorsPN.text.primary,
    },
    // Hover style
    '&:hover': {
        color: colorsPN.text.brand,
    },
    // Focus style
    '&:focus': {
        color: colorsPN.text.brand,
    },
    '&:focus-visible': {
        outline: 'revert',
        borderRadius: `${metricsPN.radius['S']}px`,
    },
    [breakpointMax('xs')]: {
        width: '100%',
        margin: 0,
    },
}))

export const StyledTabGroupList = styled.section(() => ({
    ...perthNowNGNTextMixin.body.L.regular,
}))

export const StyledTabGroup = styled.article(() => ({
    h2: {
        margin: calcRem(0, 0, metricsPN.spacing['S'], 0),
    },
    p: {
        ...perthNowNGNTextMixin.body.L.regular,
    },
    li: {
        ...perthNowNGNTextMixin.body.L.regular,
        margin: 0,
    },
}))

import { DataLayerEventName, useFeature } from '@news-mono/web-common'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { nth } from 'ramda'
import { default as React, useEffect, useState } from 'react'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'
import { getCanonicalLink } from '../lib/get-canonical-link'
import { getEditorialType } from '../lib/get-editorial-type'
import { getFundingType } from '../lib/get-funding-type'
import {
    StyledPNArticleContent,
    StyledClassification,
    StyledGrid,
    StyledHeadline,
    StyledPNSharingControls,
    StyledInsetCell,
} from './PNPublicationNGN.styled'
import { PublicationProps } from '../Publication.props'
import { mappedPublicationPropsToGa4 } from '../../../__helpers'
import { maxSecondLevel } from '../lib/max-second-level'
import { PNArticleHero } from '../../../nextgen-news/perthnow/publication/PNArticleHero/PNArticleHero'
import { StyledBadgeSpan } from '../../../nextgen-news/perthnow/cards/PNArticleCard/PNArticleCardHeader/PNArticleCardHeader.styled'
import { IconZap } from '../../../icons'
import { PNByline } from '../../../nextgen-news/perthnow/publication/components/PNByline/PNByline'
import { PNTopicList } from '../../../nextgen-news/perthnow/content/TopicList/PNTopicList'
import { PNSharingStory } from '../../../nextgen-news/perthnow/publication/components/PNSharingStory/PNSharingStory'
import PNCopyLink from '../../../nextgen-news/perthnow/publication/components/PNCopyLink/PNCopyLink'
import PNCommentsCount from '../../../nextgen-news/perthnow/publication/components/PNCommentsCount/PNCommentsCount'
import { PNLiveEvent } from '../../../nextgen-news/perthnow/publication/PNLiveEvent/PNLiveEvent'
import { StyledReportingLiveBubble } from '../../../publication/LiveEvent/LiveEvent.styled'
import { PNFootnote } from '../../../nextgen-news/perthnow/publication/PNFootnote/PNFootnote'

export const PNNextGenPublication: React.FC<PublicationProps> = (props) => {
    const publication = props.data
    const content = publication.content

    const canonicalLink = getCanonicalLink(publication)
    const heroImage = publication.heroImage
    const targetedContentElement =
        props.targetedContent && props.targetedContent.position === 'inline'
            ? props.targetedContent.element
            : undefined

    const publicationKind = publication.kind
    const isEvent = publicationKind === 'event'
    const storyClassification = true
    const fundingType = getFundingType(publication, storyClassification)
    const editorialType = getEditorialType(publication, storyClassification)

    const isShopNow = publication.primaryTopic.id.includes('shop-now')
    const areTaboolaSkimlinksEnabled =
        useFeature('taboola-skimlinks') && isShopNow

    const [liveEventUpdated, setLiveEventUpdated] = useState(
        nth(0, isEvent ? publication.entries?.documents : [])?.lastUpdated ??
            publication.lastUpdated,
    )
    const websocketSetTimestamp = (
        post: EventPostV4DTO | KilledEventPostV4DTO,
    ) => setLiveEventUpdated(post.lastUpdated)

    const articleAvailablePayload = mappedPublicationPropsToGa4(publication)

    const ShareComponent: React.FC<{ hideComments?: boolean }> = ({
        hideComments = false,
    }) => {
        return (
            <StyledPNSharingControls>
                <PNCopyLink
                    onEvent={props.onEvent}
                    url={publication._self}
                    text={publication.heading}
                />
                <PNSharingStory
                    onEvent={props.onEvent}
                    url={publication._self}
                    text={publication.heading}
                    shareOptions={['facebook', 'twitter', 'email']}
                />
                {publication.allowCommenting && !hideComments && (
                    <PNCommentsCount articleUrl={publication._self} />
                )}
            </StyledPNSharingControls>
        )
    }

    useEffect(() => {
        if (articleAvailablePayload) {
            props.onEvent({
                type: DataLayerEventName.articleAvailable,
                originator: 'PerthNowPublication',
                payload: articleAvailablePayload,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StyledGrid>
            <PNArticleHero
                heroVideo={publication.heroVideo}
                heroImage={heroImage}
                heading={publication.heading}
                onEvent={props.onEvent}
                showVideoTitles={true}
                autoplayNextOptionssOverrides={{ delayDuration: 5 }}
                isVideoStickyEnabled={props.heroOptions.isVideoStickyEnabled}
                getVideoQueue={props.getVideoQueue}
                publicationPathname={props.location.pathname}
                adUnitPath={props.adState.targeting.adUnitPath}
            />
            <StyledInsetCell>
                {editorialType && (
                    <StyledClassification>
                        <StyledBadgeSpan aria-label={`${editorialType} tag`}>
                            {editorialType.toLowerCase() === 'breaking' && (
                                <IconZap />
                            )}
                            {editorialType.toLowerCase() === 'live' && (
                                <StyledReportingLiveBubble />
                            )}
                            {editorialType}
                        </StyledBadgeSpan>
                    </StyledClassification>
                )}
                <StyledHeadline text={publication.heading} />
                <PNByline
                    profile={publication.profile}
                    text={publication.byline}
                    timestamp={publication.publicationDate}
                    timestampUpdated={isEvent ? liveEventUpdated : undefined}
                    fundingType={fundingType}
                    source={publication.source}
                    isFullWidth={true}
                    url={publication._self}
                    title={publication.heading}
                    requiredAccess={publication.requiredAccess}
                    allowCommenting={publication.allowCommenting}
                    publicationKind={publication.kind}
                />
                {isShopNow && (
                    <i>
                        This content is reviewed by the PerthNow Editorial team.
                        Seven West Media may receive a commission when you buy
                        from links on our site. Prices are correct at time of
                        publication.
                    </i>
                )}
                <ShareComponent />
            </StyledInsetCell>
            <StyledPNArticleContent>
                {content && (
                    <ArticleBlockContent
                        blocks={content}
                        targetedContentElement={targetedContentElement}
                        inlinePositionedContent={props.inlinePositionedContent}
                        onEvent={props.onEvent}
                        log={props.log}
                        publicationKind={publication.kind}
                        publicationPathname={props.location.pathname}
                        adUnitPath={props.adState.targeting.adUnitPath}
                        getVideoQueue={props.getVideoQueue}
                        className={
                            fundingType
                                ? undefined
                                : 'js-taboolaReadMoreContent'
                        }
                    />
                )}
            </StyledPNArticleContent>
            <StyledInsetCell>
                {isEvent && (
                    <PNLiveEvent
                        publication={publication}
                        log={props.log}
                        adState={props.adState}
                        onEvent={props.onEvent}
                        onLiveEventUpdate={websocketSetTimestamp}
                    />
                )}
            </StyledInsetCell>
            <StyledInsetCell>
                {canonicalLink && (
                    <PNFootnote
                        text="Originally published as "
                        link={canonicalLink}
                    />
                )}
                {!isEvent && (
                    <>
                        <ShareComponent hideComments={true} />
                        <PNTopicList
                            primaryTopic={maxSecondLevel(
                                publication.primaryTopic,
                            )}
                            secondaryTopics={publication.secondaryTopics}
                        />
                    </>
                )}
                {areTaboolaSkimlinksEnabled && (
                    <div id="tbla-content-recirc-placement"></div>
                )}
            </StyledInsetCell>
        </StyledGrid>
    )
}
PNNextGenPublication.displayName = 'PNNextGenPublication'

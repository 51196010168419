import styled from '@emotion/styled'
import { calcRem } from '../../../../../__styling'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'
const { spacing } = metricsPN
export const StyledPNSharingWrapper = styled('button')({
    ...perthNowNGNTextMixin.label.M.semibold,
    minWidth: calcRem(115),
    backgroundColor: colorsPN.fill.brand.weak,
    padding: calcRem(spacing['2XS'], spacing['XS']),
    border: 'none',
    borderRadius: calcRem(spacing['2XS']),
    color: colorsPN.text.brand,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    svg: {
        marginLeft: calcRem(spacing['2XS']),
    },
    '&:hover': {
        backgroundImage: `linear-gradient(${colorsPN.fill.hover.brand} 0 0)`,
    },
    '&:active': {
        color: colorsPN.text.pressed,
        backgroundImage: `linear-gradient(${colorsPN.fill.pressed.brand} 0 0)`,
    },
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
})

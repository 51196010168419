import { useMutation } from '@tanstack/react-query'
import { ChangeEmailFormProps } from '../ChangeEmail/ChangeEmail'
import { getAuth0UpdateEmailActionPath } from '@news-mono/web-common'
import { ChangeEmailBannerType } from '../ChangeEmail/helpers/change-email-banner-helper'

export const useChangeEmail = ({
    userId,
    email,
    currentPassword,
    newEmail,
}: ChangeEmailFormProps) => {
    return useMutation({
        mutationKey: ['change-email-request'],
        mutationFn: async () => {
            const result: {
                result: boolean
                bannerType: ChangeEmailBannerType
            } = {
                result: false,
                bannerType: 'unknown',
            }

            const response = await fetch(getAuth0UpdateEmailActionPath, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    userId,
                    current_password: currentPassword,
                    new_email: newEmail,
                }),
            })

            if (response.status === 401 || response.status === 403) {
                // Send an incorrect password banner through
                result.bannerType = 'incorrect-password'
            } else if (response.status === 200 || response.status === 201) {
                // Send a successful banner through
                result.bannerType = 'success'
                result.result = true
            }

            return result
        },
    })
}

import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../__styling/style-mixins'
import { colorsPN, metricsPN, tokens } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const StyledFootnote = styled.p(() => {
    return [
        {
            ...perthNowNGNTextMixin.body['L'].regular,
            fontWeight: 400,
            color: colorsPN.text.primary,
            marginTop: calcRem(metricsPN.spacing['L']),
            a: {
                color: colorsPN.text.brand,
                fontWeight: 500,
                textDecoration: 'none',

                '&:hover': {
                    // Override StyledTextLink styling
                    backgroundColor: 'unset',
                    color: colorsPN.text.brand,
                    textDecoration: 'underline',
                },

                '&:active': {
                    // Override StyledTextLink styling
                    backgroundColor: 'unset',
                    color: colorsPN.text.pressed,
                },

                '&:focus-visible': {
                    outline: 'revert',
                },
            },
        },
        breakWords,
    ]
})

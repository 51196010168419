import { RenderTarget } from '@news-mono/web-common'

export function isTabletOrMobileBrowser(renderTarget: RenderTarget) {
    // fail conditional if SSR is triggered or a VR test
    if (
        typeof window !== 'undefined' &&
        window &&
        window.navigator &&
        renderTarget === 'web'
    ) {
        const agent = window.navigator.userAgent

        if (agent) {
            const regex = /android|iPhone|iPad|iPod/i
            return regex.test(agent)
        }
    }

    return false
}

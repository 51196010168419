import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import { perthNowNGNTextMixin } from '../../../../../mixins'
import { calcRem } from '../../../../../__styling'

const { text, fill, stroke } = colorsPN
const { spacing, radius } = metricsPN

export const Comments = styled(WebLink)(() => ({
    display: 'flex',
    padding: calcRem(spacing['2XS'], spacing['XS']),
    flexDirection: 'row',
    alignItems: 'center',
    color: text.brand,
    alignSelf: 'center',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    borderRadius: `${radius['S']}px`,
    cursor: 'pointer',
    span: {
        display: 'flex',
    },
    '&:hover': {
        textDecoration: 'underline',
    },
    '&:focus-visible': {
        outline: 'revert',
    },
    '&:active': {
        color: `${fill.brand.strong}`,
    },
    '&:active svg path': {
        stroke: `${fill.brand.strong}`,
    },
}))

export const CommentsSpan = styled('span')(() => [
    {
        ...perthNowNGNTextMixin.label.M.semibold,
        border: 'none',
        borderRadius: calcRem(spacing['2XS']),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: calcRem(spacing['2XS']),
    },
])

interface IconWrapperProps {
    size?: number
}

export const StyledIconWrapper = styled('div')<IconWrapperProps>(
    ({ size }) => ({
        height: (size && calcRem(size)) || calcRem(16),
        width: (size && calcRem(size)) || calcRem(16),
        padding: calcRem(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: calcRem(4),
    }),
)

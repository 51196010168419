import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem } from '../../../../__styling'
import { Timestamp } from '../../../../typography/Timestamp/Timestamp'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const StyledPNLiveEventByline = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: calcRem(metricsPN.spacing['S']),
})
export const StyledPNLiveEventAuthorWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

export const StyledPNLiveEventHeadshot = styled('img')({
    width: calcRem(42),
    height: calcRem(42),
    borderRadius: '50%',
    marginRight: metricsPN.spacing['2XS'],
})
export const StyledPNLiveEventHeadshotLink = styled(WebLink)({
    display: 'flex',
})

export const StyledAuthorTimestampWrapper = styled('div')({})

export const StyledPNLiveEventTimestamp = styled(Timestamp)([
    {
        color: colorsPN.text.secondary,
        marginBottom: metricsPN.spacing['4XS'],
        ...perthNowNGNTextMixin.label.M.semibold,
    },
])

export const StyledPNLiveEventAuthor = styled('span')([
    {
        color: colorsPN.text.primary,
        ...perthNowNGNTextMixin.label.M.medium,
    },
])

export const StyledPNLiveEventBylineTextLink = styled(WebLink)({
    textDecoration: 'none',
    'span:hover, span:focus': {
        color: colorsPN.text.brand,
        textDecoration: 'underline',
    },
})

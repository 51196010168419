import styled from '@emotion/styled'
import { calcRem } from '../../../../../__styling'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'

const { spacing } = metricsPN
export const StyledPNSharingWrapper = styled('button')(
    ({ open }: { open: boolean }) => ({
        ...perthNowNGNTextMixin.label.M.semibold,
        backgroundColor: open
            ? colorsPN.fill.pressed.brand
            : colorsPN.fill.brand.weak,
        color: open ? colorsPN.text.pressed : colorsPN.text.brand,
        padding: calcRem(spacing['2XS'], spacing['XS']),
        border: 'none',
        borderRadius: calcRem(spacing['2XS']),
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        svg: {
            marginLeft: calcRem(spacing['2XS']),
        },
        '&:hover': {
            backgroundImage: `linear-gradient(${colorsPN.fill.hover.brand} 0 0)`,
        },
        '&:active': {
            color: colorsPN.text.pressed,
            backgroundImage: `linear-gradient(${colorsPN.fill.pressed.brand} 0 0)`,
        },
        '&:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['5XS']),
        },
    }),
)

export const StyledShareOptionsPopover = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: calcRem(spacing['M'], spacing['L']),
    backgroundColor: colorsPN.fill.base,
    border: `1px solid ${colorsPN.background?.brand?.weak}`,
    borderRadius: calcRem(4),
    boxShadow: `0px 4px 8px 0px ${colorsPN.shadow.blurs25}`,
    position: 'absolute',
    zIndex: 2,
    marginTop: calcRem(spacing['3XL']),
    gap: calcRem(spacing['2XS']),
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: calcRem(metricsPN.spacing['2XL']),
        transform: 'translateX(-50%)',
        borderWidth: calcRem(10),
        borderStyle: 'solid',
        borderColor: `transparent transparent ${colorsPN.background?.brand?.weak} transparent`,
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: calcRem(metricsPN.spacing['2XL']),
        transform: 'translateX(-50%)',
        borderWidth: calcRem(8),
        borderStyle: 'solid',
        borderColor: `transparent transparent ${colorsPN.background?.base} transparent`,
    },
})

export const StyledPopoverHeader = styled('div')({
    ...perthNowNGNTextMixin.body.L.semibold,
    color: colorsPN.text.primary,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    gap: calcRem(spacing['2XS']),
    svg: {
        width: calcRem(20),
        height: calcRem(20),
    },
})

export const StyledShareOptionsWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'left',
})

export const StyledShareButton = styled.button({
    background: 'none',
    display: 'flex',
    gap: calcRem(spacing['S']),
    alignItems: 'center',
    justifyContent: 'left',
    border: 'none',
    margin: 0,
    padding: 0,
    textTransform: 'capitalize',
    cursor: 'pointer',
    width: 'auto',
    height: 'auto',
    svg: {
        width: calcRem(16),
        height: calcRem(16),
    },
    '&:hover': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: colorsPN.text.secondary,
        textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
        textDecorationThickness: calcRem(2),
    },
    '&:active': {
        span: {
            color: colorsPN.text.brand,
        },
    },
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
})

export const StyledButtonText = styled('span')({
    ...perthNowNGNTextMixin.body.L.medium,
    color: colorsPN.text.secondary,
    background: 'none',
})

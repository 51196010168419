import React from 'react'
import {
    PNTopicAccordionContainer,
    StyledChevron,
    StyledTopic,
    TopicDivider,
    TopicContainer,
    SecondaryTopicsContainer,
    ChevronContainer,
    TopicChevronAccordion,
    SecondaryTopicsItem,
    SecondaryTopicsWrapper,
    StyledLink,
    AccordionWrapper,
} from './PNTopicAccordion.styled'
import { PNTopicAccordionProps } from './PNTopicAccordion.routing'
import { RoutablePerthNowSection } from '@west-australian-newspapers/publication-types'
import { NavItem } from '@news-mono/web-common'
import { PerthNowNavItem } from '../../../../routing'

export const PNTopicAccordion: React.FC<PNTopicAccordionProps> = ({
    topics,
    perthNowSectionNavItems,
    subTopicNavItems,
}) => {
    const [showSecondaryTopics, setShowSecondaryTopics] = React.useState(false)
    const { primary } = topics

    const primaryTopic = primary.parent ?? primary
    const hasSecondaryTopic = primary.parent !== undefined

    let parentTopic: PerthNowNavItem | undefined
    let subTopic: NavItem | undefined
    let secondaryTopics: NavItem[] = []

    if (primaryTopic.id in subTopicNavItems) {
        parentTopic =
            perthNowSectionNavItems[primaryTopic.id as RoutablePerthNowSection]
        secondaryTopics = parentTopic?.subNavLinks ?? []

        if (hasSecondaryTopic) {
            subTopic = subTopicNavItems[
                primaryTopic.id as RoutablePerthNowSection
            ].find((item) => item.link === `/${primary.id}`)
        }
    }

    return (
        <PNTopicAccordionContainer aria-label="Accordion Navigation">
            <AccordionWrapper>
                <TopicChevronAccordion
                    showPadding={showSecondaryTopics}
                    onClick={() => setShowSecondaryTopics(!showSecondaryTopics)}
                    tabIndex={0}
                    aria-expanded={showSecondaryTopics}
                >
                    <TopicContainer>
                        {parentTopic && (
                            <StyledTopic isOpen={showSecondaryTopics}>
                                {parentTopic.name}
                            </StyledTopic>
                        )}
                        {subTopic && (
                            <>
                                <TopicDivider aria-hidden="true" />
                                <StyledTopic isOpen={showSecondaryTopics}>
                                    {subTopic.name}
                                </StyledTopic>
                            </>
                        )}
                    </TopicContainer>
                    {secondaryTopics.length > 0 && (
                        <ChevronContainer>
                            <StyledChevron isOpen={showSecondaryTopics} />
                        </ChevronContainer>
                    )}
                </TopicChevronAccordion>
            </AccordionWrapper>
            {secondaryTopics && (
                <SecondaryTopicsWrapper isOpen={showSecondaryTopics}>
                    <SecondaryTopicsContainer isOpen={showSecondaryTopics}>
                        {secondaryTopics.map((topic, index) => {
                            const isCurrent = topic.name === subTopic?.name

                            return (
                                <SecondaryTopicsItem
                                    isCurrent={isCurrent}
                                    key={index}
                                >
                                    <StyledLink
                                        to={`${topic.link}`}
                                        aria-current={
                                            isCurrent ? 'location' : undefined
                                        }
                                        tabIndex={showSecondaryTopics ? 0 : -1}
                                    >
                                        {topic.name}
                                    </StyledLink>
                                </SecondaryTopicsItem>
                            )
                        })}
                    </SecondaryTopicsContainer>
                </SecondaryTopicsWrapper>
            )}
        </PNTopicAccordionContainer>
    )
}

import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling'
import { Timestamp } from '../../../../content/CardTimestamp/CardTimestamp'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import {
    StyledButtonContainer,
    StyledShowMoreButton,
    StyledTruncContainer,
} from '../../../../publication/LiveEvent/Components/utility/TruncatingContainer.styled'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const StyledPNMilestonesContainer = styled('div')({
    backgroundColor: colorsPN.background.base,
    margin: 0,

    [`${StyledTruncContainer}`]: {
        background: colorsPN.background.base,
        margin: calcRem(metricsPN.spacing['L'], 0),
    },

    [`${StyledButtonContainer}`]: {
        background: colorsPN.background.base,

        '&::before': {
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)`,
        },
    },

    [`${StyledShowMoreButton}`]: {
        marginLeft: calcRem(-metricsPN.spacing['S']),
        border: 'none',
        color: colorsPN.text.brand,
        padding: 0,

        '&:hover': {
            background: 'none',
            textDecoration: 'underline',
        },

        '&:active, &:focus, &:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['4XS']),
        },
        '&:after': {
            border: 'none',
        },
        ...perthNowNGNTextMixin.body.L.medium,
    },
})

export const StyledPNMilestonesTimeline = styled('nav')({
    margin: calcRem(metricsPN.spacing['L'], metricsPN.spacing['2XS'], 0),
    borderLeft: `1px solid ${colorsPN.stroke.weak}`,
})

export const StyledPNMilestonesTimelineList = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
})
export const StyledPNMilestonesTimelineListItem = styled('li')({
    padding: 0,
    margin: 0,

    a: {
        textDecoration: 'none',

        '&:active, &:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['4XS']),
        },
    },
})

export const StyledPNMilestone = styled('div')<{ dimmed: boolean }>(
    ({ dimmed }) => ({
        position: 'relative',
        marginBottom: calcRem(metricsPN.spacing['3XL']),
        paddingTop: calcRem(metricsPN.spacing['L']),
        opacity: dimmed ? 0.2 : 1,

        '&:hover': {
            opacity: 1,

            '&::after': {
                left: calcRem(-metricsPN.spacing['3XS']),
                top: calcRem(-metricsPN.spacing['3XS']),
            },
        },

        '&::after': {
            display: 'block',
            position: 'absolute',
            borderRadius: '50%',
            content: `''`,
            left: calcRem(-metricsPN.spacing['3XS']),
            top: calcRem(-metricsPN.spacing['3XS']),
            width: calcRem(metricsPN.spacing['XS']),
            height: calcRem(metricsPN.spacing['XS']),
            backgroundColor: colorsPN.fill.brand.strong,
        },
    }),
)

export const StyledPNMilestoneTimestamp = styled(Timestamp)({
    padding: 0,
    width: 'auto',
    textAlign: 'left',
    marginLeft: calcRem(metricsPN.spacing['S']),
    backgroundColor: colorsPN.background.base,

    position: 'absolute',
    top: calcRem(-metricsPN.spacing['XS']),

    color: colorsPN.text.primary,
    ...perthNowNGNTextMixin.title.XS.semibold,
})

// The 'Key Events' title
export const StyledPNMilestonesTitle = styled('h3')({
    margin: 0,
    marginBottom: calcRem(metricsPN.spacing['XS']),
    ...perthNowNGNTextMixin.title.S.semibold,
})

// Short title of each milestone
export const StyledPNMilestoneTitle = styled('span')({
    marginLeft: calcRem(metricsPN.spacing['S']),
    display: 'block',
    color: colorsPN.text.primary,
    ...perthNowNGNTextMixin.body.L.regular,
})

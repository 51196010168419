import styled from '@emotion/styled'
import { WebNavLink } from '@news-mono/web-common'
import { calcRem } from '@news-mono/component-library'
import { perthNowNGNTextMixin } from '../../../../../../mixins/TextMixin/TextMixin.theme'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'

export const StyledSubNavWrapper = styled('section')({
    minWidth: calcRem(389),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    paddingRight: calcRem(metricsPN.spacing['XL']),
})
export const StyledSubNavTopicHeader = styled('h4')({
    ...perthNowNGNTextMixin.title.XS.bold,
    color: colorsPN.text.primary,
    margin: calcRem(0, 0, metricsPN.spacing['XL']),
})
export const StyledSubNavContainer = styled('nav')({
    margin: '0 auto',
    display: 'flex',
    '& > ul, & > div': {
        width: '33.3%',
    },
    '& > div': {
        paddingLeft: calcRem(metricsPN.spacing['XL']),
    },
    '& > :nth-child(2)': {
        borderRight: `1px solid ${colorsPN.stroke.brand.weak}`,
        borderLeft: `1px solid ${colorsPN.stroke.brand.weak}`,
    },
})
// Sub Nav container
export const StyledSubNav = styled('ul')({
    columnCount: 2,
    columnWidth: calcRem(150),
    height: calcRem(300),
    columnFill: 'auto',
    left: 0,
    listStyle: 'none',
    margin: 0,
    flexWrap: 'nowrap',
    padding: 0,
})
// Sub Nav List Item
export const StyledSubNavListItem = styled('li')({
    marginBottom: calcRem(metricsPN.spacing['XS']),
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'calc(50% - 1px)',
    '&:nth-child(odd)': {
        borderLeft: 'none',
    },

    '&:nth-child(-n+2)': {
        borderTop: 'none',
    },
    '&:last-child': {
        marginBottom: 0,
    },
})
// Sub Nav Link
export const StyledSubNavLink = styled(WebNavLink)({
    ...perthNowNGNTextMixin.body.L.semibold,
    color: colorsPN.text.primary,
    textDecoration: 'none',
    position: 'relative',
    padding: 0,
    '&:hover': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: colorsPN.text.primary,
        textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
        textDecorationThickness: calcRem(2),
    },
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
    '&:active': {
        color: colorsPN.text.pressed,
    },
    '&.is-active': {
        color: colorsPN.text.brand,
    },
})

import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../../__styling'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { StyledTopicListContainer } from '../../content/TopicList/PNTopicList.styled'
import {
    StyledPageNumberWrapper,
    StyledPaginationItem,
    StyledPaginationLink,
} from '../../../../data-controllers/Pagination/Pagination.styled'
import { perthNowNGNTextMixin } from '../../../../mixins'

const { spacing } = metricsPN

export const StyledPNLiveEventContainer = styled('div')({
    borderTop: `2px solid ${colorsPN.stroke.brand.strong}`,
    position: 'relative',

    [`${StyledTopicListContainer}`]: {
        marginBottom: spacing['L'],

        [breakpoint('sm')]: {
            marginBottom: spacing['XL'],
        },
        [breakpoint('lg')]: {
            marginBottom: spacing['L'],
        },
    },
})

// style each pagination item
export const StyledPNPagination = styled('div')({
    borderBottom: `1px solid ${colorsPN.stroke.weak}`,
    paddingBottom: metricsPN.spacing['S'],

    [breakpoint('sm')]: {
        paddingBottom: metricsPN.spacing['L'],
    },

    'button.disabled': {
        svg: {
            path: {
                stroke: colorsPN.text.disabled,
            },
        },
    },
    'a.enabled': {
        svg: {
            path: {
                stroke: colorsPN.icon.brand,
            },
        },
    },

    [`${StyledPaginationItem}, ${StyledPaginationLink}`]: {
        background: 'none !important',
        padding: 0,
        width: calcRem(metricsPN.spacing['XL']),
        height: calcRem(metricsPN.spacing['XL']),
        color: colorsPN.text.secondary,
        ...perthNowNGNTextMixin.label.M.medium,

        '&.bordered': {
            border: `1px solid ${colorsPN.stroke.weak}`,
            borderRadius: `${metricsPN.spacing['4XS']}px`,
        },

        '&:hover': {
            background: 'none',
            color: colorsPN.text.brand,
            textDecoration: 'underline',
        },

        '&.enabled-active': {
            color: colorsPN.text.brand,

            '&.bordered': {
                border: `1px solid ${colorsPN.stroke.brand.strong}`,
            },
        },

        '&:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['5XS']),
        },
    },

    [`${StyledPageNumberWrapper}`]: {
        overflow: 'visible',
        alignItems: 'center',
        gap: calcRem(metricsPN.spacing['2XS']),
    },
})

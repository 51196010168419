import { mapContentBlocks } from '@news-mono/web-common'
import React, { FC } from 'react'
import {
    StyledLiveEventSharingControls,
    StyledPNLiveEventPost,
    StyledPNLiveEventTitle,
} from './PNLiveEventPost.styled'
import { ArticleBlockContent } from '../../../../publication'
import { getPinnedChipType } from '../../../../publication/LiveEvent/helpers/get-pinned-type'
import { PNLiveEventPostByline } from './PNLiveEventPostByline'
import PNCopyLink from '../components/PNCopyLink/PNCopyLink'
import PNSharingStory from '../components/PNSharingStory/PNSharingStory'
import { LiveEventPostProps } from '../../../../publication/LiveEvent/Components/LiveEventPost'

export const PNLiveEventPost: FC<LiveEventPostProps> = ({
    post,
    eventTitle,
    log,
    adUnitPath,
    lazyLoadRef,
    onEvent,
    renderIDAnchorTag = true,
    canTruncate,
    entryType,
}) => {
    const authorName =
        post.profiles.length >= 1
            ? post.profiles.map((profile) => profile.name).join(' &')
            : post.byline

    const pinnedVariant = getPinnedChipType(post.sticky, canTruncate)

    return (
        <StyledPNLiveEventPost
            ref={lazyLoadRef}
            key={post.id}
            id={renderIDAnchorTag ? post.id : undefined}
            noBotMargin={canTruncate}
        >
            <PNLiveEventPostByline
                profile={post.profiles}
                timestamp={post.publishedDate}
                text={authorName || undefined}
                happeningPost={post.happening?.label}
                pinnedPost={pinnedVariant}
                entryType={entryType}
            />
            {post.title && (
                <StyledPNLiveEventTitle>{post.title}</StyledPNLiveEventTitle>
            )}
            <ArticleBlockContent
                blocks={mapContentBlocks(post.content.blocks)}
                onEvent={onEvent}
                log={log}
                adUnitPath={adUnitPath}
                publicationKind={'event'}
                isEventPost={true}
            />
            <StyledLiveEventSharingControls>
                <PNCopyLink
                    onEvent={onEvent}
                    url={post._self}
                    text={post.title || ''}
                />
                <PNSharingStory
                    onEvent={onEvent}
                    url={post._self}
                    text={post.title || ''}
                    shareOptions={['facebook', 'twitter', 'email']}
                />
            </StyledLiveEventSharingControls>
        </StyledPNLiveEventPost>
    )
}

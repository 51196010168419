import {
    AdEvent,
    AdState,
    AllEvents,
    EventPublication,
    LiveBlogEvents,
    SharingEvent,
} from '@news-mono/web-common'
import { default as React, useMemo } from 'react'
import { Logger } from 'typescript-log'
import {
    DebugPanel,
    useDebugPanel,
    useIsComponentOnScreen,
} from '../../../../publication/LiveEvent/helpers'
import {
    StyledPNLiveEventContainer,
    StyledPNPagination,
} from './PNLiveEvent.styled'
import { useLiveEventPaginationController } from '../../../../publication/LiveEvent/hooks/useLiveEventPaginationController'
import { PNLiveEventMilestones } from './PNLiveEventMilestones'
import { Pagination } from '../../../../data-controllers/Pagination/Pagination'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { PostState } from '../../../../publication/LiveEvent/hooks/PostState'
import { useDeepLinkHandler } from '../../../../publication/LiveEvent/hooks/useDeepLinkHandler'
import { useInterlacedAdUnits } from '../../../../publication/LiveEvent/hooks/useInterlacedAdUnits'
import { PNLiveEventTimeline } from './PNLiveEventTimeline'
import { PNTopicList } from '../../content/TopicList/PNTopicList'
import { maxSecondLevel } from '../../../../templates'

const ADUNIT_SPACING = 4
const ADUNIT_OFFSET = 4

type PNLiveEventProps = {
    publication: EventPublication
    log: Logger
    adState: AdState
    pageSize?: number
    maxPages?: number
    onEvent: (event: AllEvents) => void
    onLiveEventUpdate?: (event: KilledEventPostV4DTO | EventPostV4DTO) => void
}

export const PNLiveEvent = ({
    publication,
    log,
    adState,
    pageSize = 10,
    maxPages = 100,
    onEvent,
    onLiveEventUpdate,
}: PNLiveEventProps) => {
    const { isVisible, componentRef: containerRef } = useIsComponentOnScreen()
    const { debugPanelProps, addDebugEvent } = useDebugPanel('event')

    const {
        isFetching,
        postState,
        paging,
        setPage,
        handleDeepLink,
        loadUpdates,
    } = useLiveEventPaginationController({
        publicationId: publication.id,
        pageSize,
        initialPostState: {
            activePosts: publication.entries.documents,
            stickyPosts: publication.entries.stickies,
            milestonePosts: publication.entries.milestones,
            queuedPosts: [],
            postCount: publication.entries.total,
        },
        onLiveEventUpdate,
        addDebugEvent,
    })

    const deepLinkPostId = useDeepLinkHandler({
        handleDeepLink,
        addDebugEvent,
    })

    const debugObject = useMemo(
        () =>
            debugPanelProps.isEnabled
                ? {
                      isVisible,
                      deepLinkId: deepLinkPostId,
                      activePosts: postState.activePosts.map((post) => post.id),
                      stickyPosts: postState.stickyPosts.map((post) => post.id),
                      milestonePosts: postState.milestonePosts.map(
                          (post) => post.id,
                      ),
                      queuedPosts: postState.queuedPosts.map((post) => post.id),
                      totalPostCount: postState.postCount,
                      isFetching,
                      paging,
                  }
                : {},
        [
            debugPanelProps.isEnabled,
            deepLinkPostId,
            isFetching,
            isVisible,
            paging,
            postState,
        ],
    )

    return (
        <StyledPNLiveEventContainer ref={containerRef}>
            <LiveEventContent
                publication={publication}
                postState={postState}
                deepLinkPostId={deepLinkPostId}
                adState={adState}
                onEvent={onEvent}
                log={log}
            />
            <PNTopicList
                primaryTopic={maxSecondLevel(publication.primaryTopic)}
                secondaryTopics={publication.secondaryTopics}
            />
            {postState.postCount > paging.pageSize && (
                <StyledPNPagination>
                    <Pagination
                        itemCount={postState.postCount}
                        maxPages={maxPages}
                        setPage={setPage}
                        paging={paging}
                        onEvent={onEvent}
                        hideNavText={true}
                        hideItemBorder={false}
                        hideJumpButton={true}
                    />
                </StyledPNPagination>
            )}
            <DebugPanel {...debugPanelProps} debugObject={debugObject} />
        </StyledPNLiveEventContainer>
    )
}

type LiveEventDisplayProps = {
    publication: EventPublication
    postState: PostState
    deepLinkPostId?: string
    triggerLazyLoad?: () => void
    adState: AdState
    onEvent: (event: AllEvents) => void
    log: Logger
}

const LiveEventContent = ({
    publication,
    postState: { activePosts, stickyPosts, milestonePosts },
    deepLinkPostId,
    triggerLazyLoad,
    adState,
    onEvent,
    log,
}: LiveEventDisplayProps) => {
    const hasMilestones = milestonePosts.length > 0
    const adUnits = useInterlacedAdUnits({
        listSize: activePosts.length,
        spacing: ADUNIT_SPACING,
        offset: ADUNIT_OFFSET,
    })

    if (activePosts.length === 0) {
        return null
    }

    return (
        <>
            {hasMilestones && (
                <PNLiveEventMilestones
                    milestones={milestonePosts}
                    onEvent={onEvent}
                />
            )}
            <PNLiveEventTimeline
                posts={activePosts}
                adUnits={adUnits}
                pinnedPosts={stickyPosts}
                eventTitle={publication.heading}
                log={log}
                triggerLazyLoad={triggerLazyLoad}
                onEvent={onEvent}
                adState={adState}
                deepLinkedPostID={deepLinkPostId}
                entryType={publication.entryType}
            />
        </>
    )
}

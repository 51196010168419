import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { calcRem } from '../../../__styling'
import { PNPopoverArrowPosition } from './PNPopover'
import { breakpointMax } from '../../../__styling/style-functions/breakpoint'
import { perthNowNGNTextMixin } from '../../../mixins/TextMixin/TextMixin.theme'

const { background, text, icon, stroke } = colorsPN

export const PNPopoverContainer = styled.div<{
    arrowPosition: PNPopoverArrowPosition
}>(({ arrowPosition }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: arrowPosition === 'left' ? 'flex-start' : 'flex-end',
    [breakpointMax('xs')]: {
        justifyContent: 'flex-start',
    },
}))

export const PNPopoverWrapper = styled.div(
    ({
        visible,
        arrowPosition = 'left',
        popoverArrowLeft = 0,
        PNPopoverContainerWidth = 0,
    }: {
        visible: boolean
        arrowPosition: PNPopoverArrowPosition
        popoverArrowLeft: number
        PNPopoverContainerWidth: number
    }) => {
        const calcRemPopoverArrowLeft = calcRem(popoverArrowLeft)
        const calcRemPNPopoverContainerWidth = calcRem(PNPopoverContainerWidth)
        const leftProps =
            arrowPosition === 'left'
                ? calcRemPopoverArrowLeft
                : calcRemPNPopoverContainerWidth
        return {
            position: 'absolute',
            backgroundColor: background?.base,
            zIndex: 21,
            borderRadius: calcRem(metricsPN.radius['L']),
            border: `2px solid ${background?.brand?.weak}`,
            padding: calcRem(
                metricsPN.spacing['M'],
                metricsPN.spacing['L'],
                metricsPN.spacing['XS'],
                metricsPN.spacing['L'],
            ),
            top: '105%',
            left: 0,
            visibility: visible ? 'visible' : 'hidden',
            opacity: visible ? 1 : 0,
            '&:focus-visible': {
                outline: 'revert',
                outlineOffset: calcRem(metricsPN.spacing['5XS']),
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: '100%',
                left: leftProps,
                transform: 'translateX(-50%)',
                borderWidth: calcRem(12),
                borderStyle: 'solid',
                borderColor: `transparent transparent ${background?.brand?.weak} transparent`,
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '100%',
                left: leftProps,
                transform: 'translateX(-50%)',
                borderWidth: calcRem(9),
                borderStyle: 'solid',
                borderColor: `transparent transparent ${background?.base} transparent`,
            },
            [breakpointMax('xs')]: {
                '&::before': {
                    left: calcRem(popoverArrowLeft),
                },
                '&::after': {
                    left: calcRem(popoverArrowLeft),
                },
            },
        }
    },
)

export const PNPopoverHeader = styled.div(() => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr 15px',
    gridTemplateRows: 'auto auto',
    width: '100%',
    marginBottom: calcRem(metricsPN.spacing['2XS']),
    whiteSpace: 'nowrap',
    svg: {
        width: calcRem(20),
        marginLeft: calcRem(metricsPN.spacing['2XS']),
    },
    [breakpointMax('xs')]: {
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: 'auto auto auto',
        flexDirection: 'column',
        alignItems: 'center',
        '& svg:last-child': {
            display: 'block',
            bottom: calcRem(10),
            left: calcRem(10),
        },
    },
}))

export const PNPopoverTitle = styled.h4(() => ({
    ...perthNowNGNTextMixin?.body?.L?.semibold,
    margin: 0,
}))

export const PNPopoverContent = styled.p(() => ({
    ...perthNowNGNTextMixin?.body?.L?.regular,
}))

export const LearnMoreLink = styled(WebLink)(() => ({
    ...perthNowNGNTextMixin?.body?.L?.medium,
    color: text?.brand,
    textDecoration: 'none',
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '&:active': {
        color: text?.pressed,
    },
}))

export const PNPopoverLabelWrapper = styled.div<{ isVisible?: boolean }>(
    ({ isVisible }) => ({
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        padding: calcRem(0, 0, metricsPN.spacing['2XS'], 0),
        svg: {
            width: calcRem(16),
            verticalAlign: 'text-top',
            margin: calcRem(0, metricsPN.spacing['XS']),
            path: {
                stroke: isVisible ? icon?.secondary : icon?.brand,
            },
        },
        '&:hover': {
            span: {
                color: text?.secondary,
            },
            svg: {
                path: {
                    stroke: icon?.secondary,
                },
            },
        },
        // Focus style
        '&:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['5XS']),
            borderRadius: `${metricsPN.radius['S']}px`,
        },
    }),
)

export const PNPopoverLabel = styled.span<{ isVisible?: boolean }>(
    ({ isVisible }) => ({
        color: isVisible ? text?.secondary : text?.brand,
        verticalAlign: 'middle',
        cursor: 'pointer',
        [breakpointMax('xs')]: {
            marginRight: 0,
        },
    }),
)
export const StyledButton = styled.button(() => ({
    background: 'none',
    border: 'none',
    width: calcRem(20),
    height: calcRem(20),
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: calcRem(15),
    marginLeft: calcRem(7),
    position: 'relative',
    opacity: 0.8,
    svg: {
        width: calcRem(20),
        stroke: icon?.secondary,
    },
    [breakpointMax('xs')]: {
        left: calcRem(10),
    },
}))

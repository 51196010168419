import React from 'react'
import {
    StyledAuthorTimestampWrapper,
    StyledPNLiveEventAuthor,
    StyledPNLiveEventByline,
    StyledPNLiveEventBylineTextLink,
    StyledPNLiveEventHeadshot,
    StyledPNLiveEventHeadshotLink,
    StyledPNLiveEventTimestamp,
} from './PNLiveEventPostByline.styled'
import { EventPostBylineProps } from '../../../../publication/Byline/EventPostByline'

export const PNLiveEventPostByline: React.FC<EventPostBylineProps> = ({
    text,
    profile,
    timestamp,
}) => {
    /* Only create a link if one author exists */
    const authorProfilePage =
        profile?.length === 1 ? `/profile/${profile[0].slug}` : ''

    /* Only show a headshot if one author exists */
    const authorHeadshot =
        profile?.length === 1 ? profile[0]?.thumbnailPhoto : undefined

    const authorName =
        profile && profile.length >= 1
            ? profile.map((profile) => profile.name).join(' &')
            : text

    const timestampFormat = 'relativeLongFormat'

    return (
        <StyledPNLiveEventByline>
            {authorHeadshot && (
                <StyledPNLiveEventHeadshotLink to={authorProfilePage}>
                    <StyledPNLiveEventHeadshot
                        src={authorHeadshot}
                        alt={'Author Headshot'}
                    />
                </StyledPNLiveEventHeadshotLink>
            )}
            <StyledAuthorTimestampWrapper>
                {authorProfilePage ? (
                    <StyledPNLiveEventBylineTextLink to={authorProfilePage}>
                        <>
                            {timestamp && (
                                <StyledPNLiveEventTimestamp
                                    timestamp={timestamp}
                                    timestampFormat={timestampFormat}
                                />
                            )}
                            <StyledPNLiveEventAuthor>
                                {authorName}
                            </StyledPNLiveEventAuthor>
                        </>
                    </StyledPNLiveEventBylineTextLink>
                ) : (
                    <>
                        {timestamp && (
                            <StyledPNLiveEventTimestamp
                                timestamp={timestamp}
                                timestampFormat={timestampFormat}
                            />
                        )}
                        <StyledPNLiveEventAuthor>
                            {authorName}
                        </StyledPNLiveEventAuthor>
                    </>
                )}
            </StyledAuthorTimestampWrapper>
        </StyledPNLiveEventByline>
    )
}
